import React, { useEffect, useState } from 'react'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler'
import AuthGuard from '../../components/ui/AuthGuard'
import AdminSidebar from '../../components/adminSidebar'
import { Link } from 'gatsby'
import {
	Button, Checkbox, FormControlLabel,
	FormGroup, MenuItem,
	Paper, Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Dialog, DialogTitle, DialogContent
} from '@material-ui/core'
import styled from 'styled-components'
import {
	AdminDealsDocument,
	DealFrequency,
	useAdminDealsQuery,
	useCreateAdminDealsMutation, useCreateDealBidMutation, useEditDealBidMutation
} from '../../apollo/generated'
import { getLocalToken } from '../../utils/client'
import ResponsiveModal from '../../components/ui/ResponsiveModal'

const Wrapper = styled.div`
	background-color: white;
	padding: 20px;
	color: black;
`

interface GroupState {
	dealId: string
	name: string,
	zipCodes: string[],
	national: boolean
	code: string,
	multiplier: number,
	multiplierDescription: string,
	image: string,
	title: string,
	description: string,
	landingUrl: string,
	frequency: DealFrequency
	groupDiscountPercentage: number
	visible: boolean
}

interface NewContractor {
	contractorEmail: string,
	priceInCents: number
	priceText: string
	dealId: string
}

const ScrollableTableCell = styled.div`
	 max-height: 220px;
	 overflow: scroll;
`;

interface DealContractUser {
	property: {
		id: string
		streetName: string
		houseNumber: string
	}
	user: {
		id: string
		email: string
	}
}

const DealContractUsersTable: React.FC = ({ users }: { users: DealContractUser[]}) => {
	if (!users) {
		return null;
	}
	if (users.length === 0) {
		return <div>No users joined yet</div>
	}
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Property Id</TableCell>
					<TableCell>House n.</TableCell>
					<TableCell>Street n.</TableCell>
					<TableCell>Email</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{users.map(u => {
					return (
						<TableRow key={u.property.id}>
							<TableCell component="th" scope="row">
								{u.property.id}
							</TableCell>
							<TableCell component="th" scope="row">
								{u.property.houseNumber}
							</TableCell>
							<TableCell component="th" scope="row">
								{u.property.streetName}
							</TableCell>
							<TableCell component="th" scope="row">
								{u.user.email}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	)
}

const DealBidderDialog: React.FC = (
		{onUpdate, onClose, open, onButtonClick, emailOfEditedBidder, dealBidderId = '' }
	) => {
	const [bidderData, setBidderData] = useState({
		contractorEmail: '',
		priceInCents: 0,
		priceText: '',
	})
	useEffect(() => {
		onUpdate(bidderData)
	}, [bidderData])
	const signupUrl = dealBidderId ? `/deals/signup/?dealBidderId=${dealBidderId}` : null;
	return (
		<Dialog
			open={open}
			onClose={() => onClose()}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title"><Typography>Add Contractor</Typography></DialogTitle>
			<DialogContent>
				<FormGroup>
					{signupUrl ? <Link to={signupUrl}>{signupUrl}</Link> : undefined}
					{
						emailOfEditedBidder ?
							<div>{emailOfEditedBidder}</div>
							: <TextField label="Contractor email" disabled={emailOfEditedBidder} value={bidderData.contractorEmail} onChange={(e) => (e.persist(), setBidderData(nc => ({...nc, contractorEmail: e.target.value})))} />
					}
					<TextField label="Price in cents" type={'number'} value={bidderData.priceInCents} onChange={(e) => (e.persist(), setBidderData(nc => ({...nc, priceInCents: parseInt(e.target.value)})))} />
					<TextField label="Price text" type={'text'} value={bidderData.priceText} onChange={(e) => (e.persist(), setBidderData(nc => ({...nc, priceText: e.target.value})))} />
					<Button
						onClick={() => onButtonClick(bidderData)}
					>Add</Button>
				</FormGroup>
			</DialogContent>
		</Dialog>
	)
}

const AdminMain = () => {
	const [groupState, setGroupState] = useState<GroupState>({
		dealId: null,
		name: '',
		zipCodes: [],
		national: false,
		code: null,
		multiplier: 1,
		multiplierDescription: '',
		image: '',
		title: '',
		description: '',
		landingUrl: '',
		frequency: DealFrequency.OneTime,
		groupDiscountPercentage: 10,
		visible: false,
	})
	const [ newBidder, setNewBidder ] = useState<NewContractor>({
		contractorEmail: '',
		priceInCents: 1000,
		priceText: null,
		dealId: null,
	})
	const [editedBidder, setEditedBidder] = useState({
		show: false,
		contractorEmail: null,
		dealBidderId: null,
		priceInCents: null,
		priceText: null,
	})

	const { data: { adminDeals } = { } } = useAdminDealsQuery()
	const [createDeal] = useCreateAdminDealsMutation()
	const [createDealBid, { error: createDealBidErrors }] = useCreateDealBidMutation()
	const [editDealBid] = useEditDealBidMutation()
	const [dealContractUsers, setDealContractUsers] = useState<DealContractUser[]>();

	const onChange = (property: keyof GroupState, value: string | number | string[] | boolean, shouldParseInt?: boolean) => {
		setGroupState(s => ({ ...s, [property]: shouldParseInt ? parseInt(value as string): value }))
	}
	const uploadToS3 = async (file) => {
		const myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${getLocalToken()}`);
		const formdata = new FormData();
		formdata.append("image", file, "Screenshot 2020-08-19 at 19.29.12.png");
		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: formdata,
			redirect: 'follow'
		};

		const content = await fetch(`${process.env.GATSBY_CLIENT_BASE_URL}/image-upload?action=admin-upload`, requestOptions);
		const text = await content.text()
		setGroupState((s) => Object.assign({}, s, {image: JSON.parse(text).location}));
	};
	const onClickCreateBid = async () => {
		await createDealBid({
			variables: newBidder,
			refetchQueries: [{ query: AdminDealsDocument }]
		})
		if (createDealBidErrors) {
			console.log({ createDealBidErrors });
		}
		setNewBidder(nc => ({...nc, dealId: null }))
	}
	const editDeal = (dealId: string) => {
		const deal = adminDeals.find(ad => ad.id === dealId);
		console.log("!!", deal);
		const dealData = {
			dealId,
			...deal,

			zipCodes: deal.zipCodes.map(zc => zc.code)
		}
		setGroupState(s => (
			{
				...s,
				...dealData
			}
		))
	}
	const showUsers = (bidder) => {
		const users: DealContractUser[] = [];
		for (const dc of bidder.dealContracts) {
			users.push({
				property: {
					id: dc.property.id,
					houseNumber: dc.property.houseNumber,
					streetName: dc.property.streetName,
				},
				user: {
					id: dc.property.owner.user.id,
					email: dc.property.owner.user.email,
				}
			})
		}
		setDealContractUsers(users)
	}
	const editDealBidder = (bidder) => {
		setEditedBidder(ed => ({
			...ed,
			contractorEmail: bidder.contractor.user.email,
			priceText: bidder.priceText,
			priceInCents: bidder.priceInCents,
			dealBidderId: bidder.id,
			show: true,
		}));
	}
	return (
		<DisastrousErrorHandler>
			<AuthGuard allowed="ADMIN">
				<AdminSidebar>
					<Wrapper>
						<Typography>Add group</Typography>
						<FormGroup>
							{groupState.dealId && <div>Editing: {groupState.dealId}</div>}
							<TextField label="Group name (unique)" value={groupState.name} onChange={e => onChange('name', e.target.value)}/>
							<TextField label="Zip codes (comma separated)" value={groupState.zipCodes} onChange={e => onChange('zipCodes', e.target.value.split(','))}/>
							<FormControlLabel
								control={
									<Checkbox
										checked={groupState.national}
										onChange={e => onChange('national', e.target.checked)}
										name="checkedNational"
										color="primary"
									/>
								}
								label="National"
							/>
							<TextField label="Code (unique)" value={groupState.code} onChange={e => onChange('code', e.target.value ? e.target.value.toUpperCase() : null)}/>
							<TextField label="Multiplier" type={'number'} value={groupState.multiplier} onChange={e => onChange('multiplier', e.target.value)}/>
							<TextField label="Multiplier description" value={groupState.multiplierDescription} onChange={e => onChange('multiplierDescription', e.target.value)}/>
							<TextField disabled={true} label="Image url" value={groupState.image} onChange={e => onChange('image', e.target.value)}/>
							<input type="file" id="input" onChange={(e) => uploadToS3(e.target.files[0])} />
							<TextField label="Title" value={groupState.title} onChange={e => onChange('title', e.target.value)}/>
							<TextField label="Description" value={groupState.description} onChange={e => onChange('description', e.target.value)}/>
							<TextField label="Ladning Url" value={groupState.landingUrl} onChange={e => onChange('landingUrl', e.target.value)}/>
							<TextField label="Group Discount Percentage" value={groupState.groupDiscountPercentage} onChange={e => onChange('groupDiscountPercentage', e.target.value, true)}/>
							<FormControlLabel
								control={<Checkbox checked={groupState.visible} onChange={e => onChange('visible', e.target.checked)} name="visible" />}
								label="Visible"
							/>
							<Select
								value={groupState.frequency}
								onChange={(e) => onChange('frequency', e.target.value as string)}
							>
								<MenuItem value={DealFrequency.OneTime}>One time</MenuItem>
								<MenuItem value={DealFrequency.Weekly}>Weekly</MenuItem>
								<MenuItem value={DealFrequency.Monthly}>Monthly</MenuItem>
								<MenuItem value={DealFrequency.Quarterly}>Quaterly</MenuItem>
							</Select>

						</FormGroup>
						<Button
							onClick={() => (console.log(groupState), createDeal({
								variables: groupState,
								refetchQueries: [{ query: AdminDealsDocument }]
							}))}
						>{groupState.dealId ? 'Edit deal' : 'Create deal'}</Button>
					</Wrapper>
					<Paper style={{ margin: 20, padding: 12 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Id</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Visible</TableCell>
									<TableCell>Contractor</TableCell>
									<TableCell>Zip codes</TableCell>
									<TableCell>Code</TableCell>
									<TableCell>Image</TableCell>
									<TableCell>Title</TableCell>
									<TableCell>Description</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{adminDeals && adminDeals.map(deal => {
									return (
										<TableRow key={deal.id}>
											<TableCell component="th" scope="row">
												{deal.id} <a onClick={() => editDeal(deal.id)}>(edit)</a>
											</TableCell>
											<TableCell component="th" scope="row">
												{deal.name}
											</TableCell>
											<TableCell component="th" scope="row">
												{deal.visible ? 'yes' : 'no'}
											</TableCell>
											<TableCell component="th" scope="row">
												<div>
													<a
														href={"#"}
														onClick={(e) => (e.preventDefault(), setNewBidder(nc => ({...nc, dealId: deal.id })))}
													>Add contractor</a>
												</div>
												<DealBidderDialog
													onUpdate={(bidderData) => {
														setNewBidder(nc => ({...nc, ...bidderData }))
													}}
													open={!!newBidder.dealId}
													onClose={() => {
														setNewBidder(nc => ({...nc, dealId: null }))
													}}
													onButtonClick={async () => {
														await onClickCreateBid()
													}}
												/>
												<DealBidderDialog
													emailOfEditedBidder={editedBidder.contractorEmail}
													dealBidderId={editedBidder.dealBidderId}
													onUpdate={(bidderData) => {
														console.log({bidderData});
														setEditedBidder(nc => ({...nc, ...bidderData, contractorEmail: editedBidder.contractorEmail }))
													}}
													open={editedBidder.show}
													onClose={() => {
														setEditedBidder(nc => ({...nc, show: false }))
													}}
													onButtonClick={async () => {
														await editDealBid({
															variables: editedBidder,
															refetchQueries: [{ query: AdminDealsDocument }]
														})
														setEditedBidder(eb => ({...eb, show: false}))
													}}
												/>
												{deal.bidders.map(b => {
													return (
														<div key={b.id}>
															{`${b.contractor.user.email}: $${b.priceInCents} ${b.priceText ? b.priceText : ''}`}
															<a href={'#'} onClick={() => showUsers(b)}>(users)</a>
															<a href={'#'} onClick={() => editDealBidder(b)}>(edit)</a>
														</div>)
												})}
												<ResponsiveModal open={dealContractUsers} onClose={() => setDealContractUsers(null)} overflow={'inherit'}>
													<DealContractUsersTable users={dealContractUsers} />
												</ResponsiveModal>
											</TableCell>
											<TableCell component="th" scope="row">
												<ScrollableTableCell>
													{deal.zipCodes && deal.zipCodes.map(z => z.code).join(", ")}
												</ScrollableTableCell>
											</TableCell>
											<TableCell component="th" scope="row">
												{deal.code}
											</TableCell>
											<TableCell component="th" scope="row">
												{deal.image}
											</TableCell>
											<TableCell component="th" scope="row">
												{deal.title}
											</TableCell>
											<TableCell component="th" scope="row">
												{deal.description}
											</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</Paper>
				</AdminSidebar>
			</AuthGuard>
		</DisastrousErrorHandler>
	)
}

export default AdminMain
